import React from 'react';
import { FeatureServices, Logger } from '@feature-hub/core';
import { FaContentServiceV1 } from '@volkswagen-onehub/fa-content-service';
// import { TrackingManagerV1 } from '@volkswagen-onehub/tracking-service';

export type Content = {
  id: string
}

type FaContentService = FaContentServiceV1 & {
  content: Content
}
export interface YoutubeEmbedAppDependencies extends FeatureServices {
  readonly 'fa-content-service': FaContentService;
  // readonly 'tracking': TrackingManagerV1;
  readonly 's2:logger'?: Logger;
}
export const FeatureServicesContext = React.createContext<
YoutubeEmbedAppDependencies | undefined
>(undefined);

export function useFeatureServices(): YoutubeEmbedAppDependencies {
  const featureServices = React.useContext(FeatureServicesContext);

  if (!featureServices) {
    throw new Error('No featureServices context value was provided.');
  }

  return featureServices;
}
