import { useFeatureServices } from '../use-feature-services';
declare global {
  interface Document { hasFSEventListeners: boolean ; }
}

export const eventInfo = {
  brand: "passenger"
};


export const lead = function(num: number) {
  if (String(num).length === 1) {
      return "0" + num;
  }
  return num;
}

export const convertToISOStr = function(duration: number) {
  const hours = Math.floor(duration / 60 / 60);
  const mins = Math.floor((duration - hours * 60 * 60) / 60);
  const secs = duration % 60;

  const durationStr = lead(hours)+":"+lead(mins)+":"+lead(secs);
  return durationStr;
}

const fullscreenEvents = ['fullscreenchange', 'msfullscreenchange', 'mozfullscreenchange', 'webkitfullscreenchange'];

const handleFStoggle = function(video: object) {
  // const { tracking } = useFeatureServices();

  const tracking = {
    track: (a: string, b: object) => {}
  }
  
  if (!document.fullscreenElement) {
    tracking.track('VideoPlayer_FullScreen_Close', { eventInfo, video });
  } else {
    tracking.track('VideoPlayer_Fullscreen_Open', { eventInfo, video });
  };
}

export const addFSEventListeners = function(video: object) {
  fullscreenEvents.forEach(event =>
    document.addEventListener(event, () => handleFStoggle(video), false)
  );
}

export const removeFSEventListeners = function(video: object) {
  fullscreenEvents.forEach(event =>
    document.removeEventListener(event, () => handleFStoggle(video), false)
  );
}
