import React, { useEffect } from 'react';
import {Container} from '@volkswagen-onehub/components-core';
import {Content, useFeatureServices} from './use-feature-services';
import Video from './components/Video';

export function App(): JSX.Element {
  const {'fa-content-service': contentService} = useFeatureServices();

  const content: Content = contentService.content || {id: undefined};

  const {id} = content;

  useEffect(() => {
    console.log("new youtube embed fa loaded");
    console.log('React.version', React.version);
  }, [])

  return (
    <Container wrap="always">
      <Video id={id} />
    </Container>
  );
}
