import {FeatureAppDefinition} from '@feature-hub/core';
import {ReactFeatureApp} from '@feature-hub/react';
import React from 'react';
import {App} from './app';
import {
  FeatureServicesContext,
  YoutubeEmbedAppDependencies,
} from './use-feature-services';

export const featureAppDefinition: FeatureAppDefinition<
  ReactFeatureApp,
  YoutubeEmbedAppDependencies
> = {
  dependencies: {
    featureServices: {
      'fa-content-service': '^1.0.0',
      // 'tracking': '^1.0.0'
    }
  },
  optionalDependencies: {
    featureServices: {
      's2:logger': '^1.0.0'
    }
  },

  create: ({featureServices}) => ({
    render() {
      return (
        <FeatureServicesContext.Provider value={featureServices}>
          <App />
        </FeatureServicesContext.Provider>
      );
    }
  })
};

export default featureAppDefinition;
