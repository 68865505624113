import React, { useEffect, useState } from "react";
import {
  AspectRatioContainer,
  Container,
 } from "@volkswagen-onehub/components-core";
import YouTube, { Options } from 'react-youtube';
import { convertToISOStr, addFSEventListeners, removeFSEventListeners, eventInfo } from "./helpers";

type Props = {
  id: string;
};
interface OnPlayerReadyEvent extends YT.PlayerEvent {
  target: YT.PlayerEvent['target'] & {
     getVideoData(): {
       title: string;
       video_id: string;
     }
  }
}

let progress: any;

const Video = (props: Props) => {
  const { id } = props;
  // const { tracking } = useFeatureServices();
  const tracking = {
    track: (a: string, b: object) => {}
  }

  const [ player, setPlayer ] = useState<YT.Player | undefined>();
  const [ video, setVideo ] = useState({});
  const [ hasStarted, setHasStarted ] = useState(false);
  const [ isLive, setIsLive ] = useState(false);
  const [ milestones, setMilestones ] = useState<number[] | []>([10, 25, 50, 75, 90]);

  const checkVidProgress = () => {
    const current = player?.getCurrentTime();
    const duration = player?.getDuration();
    if (current && duration && player && !isLive) {
      const percentage = current/duration * 100;
      if (percentage > milestones[0]) {
        tracking.track('VideoPlayer_VideoMilestone_Reached', { eventInfo, video: {
         ...video,
         milestone: milestones[0],
         timeplayed: convertToISOStr(Math.floor(player.getDuration())),
        } })
        const updatedMilestones = milestones;
        updatedMilestones.shift();
        setMilestones(updatedMilestones);
      }
    }
  }

  const onPlayerReady = (event: OnPlayerReadyEvent) => {
    setPlayer(event.target);
    const videoDetails = {
      playerName: "YouTube",
      title: event.target.getVideoData().title,
      id: event.target.getVideoData().video_id,
      platform: "YouTube",
      url: event.target.getVideoUrl(),
      length: convertToISOStr(event.target.getDuration())
    }
    setVideo(videoDetails);
    if (event.target.getDuration() === 0) {
      setIsLive(true);
    }
  }

  const onPlayerStateChange = (event: YT.OnStateChangeEvent) => {
    const state = YT.PlayerState;
    if (player) {
      if (event.data === state.PLAYING && !hasStarted) {
        setHasStarted(true);
        progress = setInterval(checkVidProgress, 100);
        tracking.track('VideoPlayer_VideoPlay_Click', { eventInfo, video })
        tracking.track('VideoPlayer_Video_Start', { eventInfo, video })
      } else if (event.data === state.PLAYING) {
        progress = setInterval(checkVidProgress, 100);
        tracking.track('VideoPlayer_VideoPlay_Click', { eventInfo, video })
      } else if (event.data === state.PAUSED) {
        clearInterval(progress);
        tracking.track('VideoPlayer_VideoPause_Click', { eventInfo, video })
      } else if (event.data === state.ENDED) {
        clearInterval(progress);
        tracking.track('VideoPlayer_VideoMilestone_Reached', { eventInfo, video: {
          ...video,
          milestone: 100,
          timeplayed: convertToISOStr(Math.floor(player.getDuration())),
        } })
      }
    }
  }

  useEffect(() => {
    if (Object.keys(video).length > 0) {
      tracking.track('VideoPlayer_VideoDetails_Load', { eventInfo, video })
      addFSEventListeners(video);
    } else {
      removeFSEventListeners(video);
    }
  }, [video])

  const opts: Options = {
    width: '100%',
    height: '100%',
    playerVars: {
      'autoplay': 0,
      'controls': 1,
      'rel': 0,
      'modestbranding': 1
    },
  };

  return (
    <Container horizontalAlign="center" stretchContent>
      <AspectRatioContainer ratio="16:9">
        <YouTube
          videoId={id}
          opts={opts}
          onReady={onPlayerReady}
          onStateChange={onPlayerStateChange}
        />
      </AspectRatioContainer>
    </Container>
  );
};

export default Video;
